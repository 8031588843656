import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import history from "../history";
import showErrorToast from "./../helpers/response-error";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

const ENTRY_ROUTE = "/driver-login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    notification.error({
      message: "Error",
    });

    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      // showErrorToast("Session closed ....you have been logged out!");
      notification.error({
        message: "Session closed ....you have been logged out!",
      });
      localStorage.removeItem("auth_id");
      localStorage.removeItem("auth_name");
      localStorage.removeItem("auth_role");
      localStorage.removeItem("auth_token");
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 400) {
      console.log(error);
      if (
        error &&
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.error
      ) {
        const errorObj = error.response.data.error;
        for (const key in errorObj) {
          if (Array.isArray(errorObj[key])) {
            notification.error({
              message: errorObj[key].map((errorMessage) => errorMessage),
            });
          } else {
            notification.error({ message: errorObj[key] });
            break;
          }
        }
      } else {
        notification.error("Opps! Something went wrong. Please try again");
      }
    }
    // if (error.response.status === 403) {
    // 	notificationParam.message = 'Authentication Fail'
    // }
    // if (error.response.status === 404) {
    // 	notificationParam.message = 'Not Found'
    // }
    // if (error.response.status === 500) {
    // 	notificationParam.message = 'Internal Server Error'
    // }
    // if (error.response.status === 508) {
    // 	notificationParam.message = 'Time Out'
    // }

    // if (notificationParam.message) {
    // 	notification.error(notificationParam.message);
    // }

    return Promise.reject(error);
  }
);

export default service;
